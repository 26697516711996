/* webform.css */
.wrapper {
    display: flex;
  }
  
  #content {
    flex: 1;
  
  }
  
  .headtitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .controls {
    display: flex;
    flex-direction: column;
   
    justify-content: center;
    gap: 10px;
    align-items: stretch;
    margin-bottom: 20px;
  }
  
  .controls label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .controls input[type="number"] {
    width: 60px;
    margin-left: 10px;
  }
  
  .imageUploadLabel {
    display: grid;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .imageUploadLabel svg {
    margin-right: 5px;
  }
  
 .SketchBox canvas {
    border: 1px solid #000000;
    cursor: crosshair;
   
  }
  

  .sketchdiv{
    min-height: 100% !important;
  }

  .canvasmain{}