#sidebar {
    min-width: 275px;
    max-width: 275px;
    width: 100%;
    transition: all .8s ease;
    background: #fff;
    box-shadow: 3px 5px 5px rgb(0 0 0 / 10%);
    /* z-index: 4; */
    position: relative;
    top: 0px;
    left: 0px;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px;
}

.sideinr {
    overflow-y: auto;
    height: 100vh;
    min-width: 275px;
    max-width: 275px;
    padding-top: 60px;
    /* border-radius: 10px; */
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    position: fixed;
}


.sideinr ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.sideinr ul li.active {
    padding: 10px;
    color: #fff;
    border-radius: 12px;
    margin: 0px 20px 10px 20px;
    background: linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%);

}

.sideinr ul li:hover {
    color: #fff;
    border-radius: 12px;
    background: linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%);
   
}

.sideinr ul li:hover a {
    color: #fff;
}

.sideinr ul li:hover .sidemenuIcon {
    filter: brightness(150.5);
}

.sideinr ul li {
    padding: 10px;
    transition: all .1s ease;
    margin: 0px 20px 10px 20px;
    border-radius: 12px;
   
    color: #fff;
}

.sideinr ul li.active a {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.sideinr ul li a {
    color: #25252C;
    font-size: 14px;
    font-weight: 500;
}

.sideinr ul li .nav-link:focus, .sideinr ul li .nav-link:hover {
    --bs-nav-link-hover-color:  #25252C;
}


.sideinr ul li.active a span {
    margin-right: 15px;
    margin-left: 10px;
}

.sideinr ul li a span {
    margin-right: 15px;
    margin-left: 10px;
}

.sidebar_logo {
    text-align: center;
}

.drop{
    position: absolute;inset: 0 0px 0px;transform: translate3d(0px, 295px, 0px) !important;
    color:"black"
}
.mastersidebar{
    color: black;
}
.mastersidebar:hover{
    color: white !important;
}

.btn{
    border-width:0px !important;
}

.formdata{
    display: flex;
    justify-content: center;
    align-items: center;
}