/*----------------- Home Css Start ------------------- */


.banner {
    width: 100%;
    min-height: 650px;
    background-size: 100% 100%;
    background-image: url('/public/assets/images/banner_back.png');
    background-repeat: no-repeat;
    background-position: center;

}

.bnrtext {
    padding-left: 130px;
}


.bnrtext h1 {
    color: #212121;
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    font-family: 'pirulen';
    line-height: 75px;
}

.bnrtext h5 {
    color: #212121;
    font-weight: 500;
    font-size: 36px;
}


.laptopImg {
    width: 100%;
    height: 750px;
    object-fit: contain;
    position: relative;
    padding-right: 100px;
}





/*----------------- Home Css Exit ------------------ */




/*----------------- Health Css Start ------------------ */


.health_sec {
    width: 100%;
    height: auto;
    position: relative;
}

.customhead h2 {
    background: linear-gradient(269deg, #4B91CE 1.45%, #5A73B5 98.37%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 42px;
    font-family: 'pirulen';
    font-weight: 400;
}

.customhead p {
    color: #212121;
    font-size: 26px;
    font-weight: 400;
    font-family: 'PlusJakartaSans';
}

/* 
.health_sec .accordion .accordion-button:not(.collapsed) {
    border: none;
    font-weight: 500;
}

.health_sec .accordion .accordion-body {
    line-height: 150%;
    color: #000;
}


.health_sec .accordion .accordion-body {
    line-height: 150%;
    color: #6F6C90;
}

.health_sec .accordion-header {
    border-radius: 50px;
}


.health_sec button.accordion-button.collapsed {
    border: none;
    color: #000639;
    font-weight: 500;
    font-size: 22px;
}


.health_sec .accordion-item {
    background: transparent !important;
    border: none !important;
    border-top: 1px solid #ccc !important;
}

.health_sec .accordion-item:first-of-type .accordion-button {
    border: none;
    font-weight: 500;
    background: var(--Gradient, linear-gradient(190deg, #B60405 65.13%, #1A2AB4 86.88%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 22px;
}

.health_sec .accordion-item:first-of-type .accordion-button:focus {
    box-shadow: none;
}

.health_sec .accordion .accordion-button {
    color: #ffffff;
    padding: 15px;
}


.health_sec .accordion .accordion-button:focus {
    box-shadow: none;
}

.health_sec .accordion-button::after {
    filter: none;
}

.health_sec button.accordion-button.collapsed::after {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.health_sec .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-image: url(/public/assets/icons/minus.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: none;
}

.health_sec .accordion-button::after {
    width: 25px;
    height: 25px;
    background-image: url(/public/assets/icons/add.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.health_sec .accordion-button:not(.collapsed)::after {
    width: 25px;
    height: 25px;
    background-image: url(/public/assets/icons/minus.png) !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.health_sec .accordion-button:not(.collapsed) {
    background: var(--Gradient, linear-gradient(190deg, #B60405 65.13%, #1A2AB4 86.88%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #000;
    font-size: 22px;
}

.health_sec .accordion-item:first-of-type {
    border-top: 0px !important;
} */

.gen_question {
    width: 100%;
    height: auto;
}

.gen_question ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.gen_question ul li {
    margin-bottom: 30px;
}

.gen_question ul li a {
    text-decoration: none;
    color: #6F6C90;
    font-size: 24px;
    font-weight: 500;
}

.addblock {
    display: flex;
    align-items: center;
    padding-right: 135px;
}


.gen_question ul li a {
    color: #575757;
    font-size: 20px;
    font-weight: 400;
}

.gen_question ul li.active a {
    color: #575757;
    font-size: 20px;
    font-weight: 400;
}

.plus_icon {
    width: 50px;
}

.healthImg {
    width: 100%;
    object-fit: contain;
    position: relative;
}

.backshape1 {
    width: 300px;
    object-fit: contain;
    position: absolute;
    top: 20px;
    left: 40%;
    z-index: -1;
}

/*----------------- Health Css Exit ------------------ */



/*----------------- Escape_sec Css Start ------------------ */

.escape_sec {
    width: 100%;
    height: auto;
    position: relative;
}

.escape_sec .backshape2 {
    width: 400px;
    object-fit: contain;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
}

.escape_sec .customhead h2 {
    background: linear-gradient(269deg, #4B91CE 1.45%, #5A73B5 98.37%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 39px;
    font-family: 'pirulen';
    font-weight: 700;
}

.escape_sec .customhead h5 {
    color: #212121;
    font-size: 35px;
    font-weight: 500;
}


.prescription {
    width: 100%;
    height: auto;
}

.prescription ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.prescription ul li.active {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #47A0D8;
    border-radius: 5px;
}

.prescription ul li {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #A3A3A3;
    border-radius: 5px;
}

.prescription ul li a {
    text-decoration: none;
    color: #8F8C8C;
    font-size: 24px;
    font-weight: 500;
}

.prescription .addblock {
    display: flex;
    align-items: center;
}


.prescription ul li a {
    color: #575757;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prescription ul li.active a {
    font-size: 22px;
    font-weight: 500;
    color: #47A0D8;
}

.prescription ul li.active a .goarrow {
    font-size: 30px;
    color: #47A0D8;
}

.prescription ul li a .goarrow {
    font-size: 30px;
    color: #8F8C8C;
}


.e-prescriptions_box {
    padding: 50px 25px;
    border: 1px solid #47A0D8;
    border-radius: 10px;
}

a.godashboard {
    color: #47A0D8;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'PlusJakartaSans';
    text-decoration: none;
}

a.godashboard span {
    font-size: 32px;
    margin-left: 5px;
}

.e-prescriptions_text p {
    font-size: 20px;
    font-weight: 400;
    color: #575757;
}

.e-prescriptions_text h3 {
    font-size: 32px;
    font-weight: 600;
    color: #212121;
}

.prescription_back {
    width: 100%;
    object-fit: contain;
    position: relative;
}



/*----------------- Escape_sec Css Exit ------------------ */



/*----------------- interractive_sec Css Start ------------------ */


.interractive_sec {
    width: 100%;
    height: auto;
    position: relative;
}

.interractive_sec .backshape2 {
    width: 400px;
    object-fit: contain;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
    transform: rotate(0deg);
}

.ineractivebox {
    border-radius: 24px;
    border: 1px solid #47A0D8;
    background: #FFF;
    box-shadow: 1px 2px 4px 0px #DCDDDD;
    padding: 20px;
    text-align: center;
    height: 250px;
}

.ineractiveicons {
    width: 60px;
}

.ineractivebox h4 {
    color: #212121;
    font-size: 22px;
    font-weight: 600;
}

.ineractivebox p {
    color: #575757;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}


/*----------------- interractive_sec Css Exit ------------------ */



/*----------------- Blog Css Start ------------------ */

.blog_sec {
    width: 100%;
    height: auto;
    position: relative;
}


.blog_sec .backshape2 {
    width: 400px;
    object-fit: contain;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: -1;
}

.blogcard.card {
    width: 100%;
    height: 500px;
    position: relative;
}

.datedetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.datedetail span {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #212121;
}

.blogcard.card .card-text h4 {
    color: #212121;
    font-size: 25px;
    font-weight: 500;
}

.bloginr {
    border: 1px solid #47A0D8;
    position: relative;
    padding: 25px;
    z-index: 2;
    border-top: 0px;
}

.bloginr::after {
    content: '';
    position: absolute;
    bottom: -35px;
    right: -35px;
    width: 70px;
    height: 70px;
    background: #fff;
    border: 1px solid #47A0D8;
    transform: rotate(45deg);
    z-index: -1;
    border-top: 0;
    border-left: 1px solid #47A0D8;
    border-bottom: 0;
    border-right: 0px;
}

a.readmore {
    color: #47A0D8;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'PlusJakartaSans';
    text-decoration: none;
}

a.readmore span {
    margin-left: 5px;
}

.circlearrow {
    width: 30px;
}



/*----------------- Blog Css Exit ------------------ */


/*----------------- Process Css Start ------------------ */




.process_sec {
    width: 100%;
    min-height: 650px;
    position: relative;
    background-size: cover;
    background-image: url('/public/assets/images/processback.png');
    background-repeat: no-repeat;
    background-position: center;
}

.customhead h5 {
    color: #212121;
    font-size: 32px;
    font-weight: 500;
}

/*----------------- Process Css Exit ------------------ */