.erkbiK div:first-child {
  overflow: visible !important;
}

.loginForm {
  padding-top: 150px;
}

.statistic-card {
  background: linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%);
  color: #fff !important;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
}

.statistic h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.statistic p.count {
  font-size: 24px;
  font-weight: bold;
}

.form-container {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-preview-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.list-group {
  margin-top: 20px;
}

.list-group-item {
  background-color: #f8f9fa;
  border: none;
}

.password-update-message {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 15px;
  border-radius: 5px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password-update-message strong {
  margin-right: 10px;
}

/* Calendar Css */

.calendar {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.calendar-main {
  flex-grow: 1;
  padding: 3em;
}

/* Header css */

.header-links {
  text-decoration: none;
  color: black;
}

.fc-day-today {
  background-color: #ffffff !important;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.page-not-found h1 {
  font-size: 6rem;
  margin: 0;
}

.page-not-found p {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.page-not-found a {
  color: #007bff;
  text-decoration: none;
  font-size: 1.25rem;
}

.page-not-found a:hover {
  text-decoration: underline;
}


.custom-modal-width .modal-content {
  max-width: 56% !important;
}

.notification-dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.notification-title {
  font-weight: bold;
}

.notification-description {
  margin-top: 5px;
}

.notification-time {
  margin-top: 5px;
  font-size: 0.8em;
  color: gray;
}

.statusActive {
  border: 1px solid;
  justify-content: center;
  display: flex;
  align-items: center;
  background: green;
  color: white;
  border-radius: 15px;
  width: 115px;
}

.statusInvalid {
  border: 1px solid;
  justify-content: center;
  display: flex;
  align-items: center;
  background: red;
  color: white;
  border-radius: 15px;
  width: 115px;
}

.statusAddendum {
  border: 1px solid;
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgb(255, 153, 0);
  color: white;
  border-radius: 15px;
  width: 115px;
}

.reportTables tr td {
  border: 1px solid;
}

.reportTables th {
  border: 1px solid;
}

.custom-card-title.card-title.h5 {
  font-size: 14px;
  font-weight: 600;
}

p.custom-card-text.card-text {
  font-size: 12px;

}

.card-headtitle {
  font-size: 20px;
  font-weight: 500;
}

.formLogHistoryCard {
  cursor: pointer;
}

.dashboardTbl .rdt_TableRow:hover {
  background: rgba(0, 0, 0, .12);
  /* Change this to the color you prefer */
  cursor: pointer;
}

.treatmentTable {
  height: 700px;
  overflow-x: auto;
}

.statistic-card-adt {
  background: linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%);
  color: #fff !important;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.erkbiK div:first-child {
  white-space: inherit !important;
}

.printStickerBtn {
  position: absolute;
  right: 5%;
  margin-top: 10px;
}

#example-collapse-text {
  overflow-x: scroll;
  height: 600px;
}

.fs10 {
  font-size: 10px;
}

.fs8 {
  font-size: 8px;
}

.fs12 {
  font-size: 12px;
}