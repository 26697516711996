/*-------------- Header Css Start ------------ */


header {
    width: 100%;
    height: auto;
    padding: 0px 0px;
    background: linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%);
}

header .nav-link {
    color: #FFF;
    font-size: 20px;
    margin-right: 15px;
    margin-left: 15px;
    position: relative;
    transition: all .3s ease;
}

header .nav-link:hover {
    color: #fff;
}

header .nav-link::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0px;
    height: 2px;
    background: #fff;
    transition: all .3s ease;

}

header .nav-link:hover::before {
    width: 100%;
}




/*-------------- Header Css Exit ------------ */