.dashboardheader {
    width: 100%;
    height: auto;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    z-index: 1;
    background: #FFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
}

.dashboardheader .navbar .nav-link {
    color: #fff;
    font-size: 18px;
}



/*--------------------------------- Dashboard Header Start --------------------------------- */


.dashboardheader .navbar-brand {
    width: 150px;
}

.dashboardheader .navbar .nav-link {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
}

.dashboardheader .navbar .nav-link.active {
    font-weight: 600;
}

.dashboardheader .navbar-nav {
    display: flex;
    align-items: center;
}

.dashboardheader .serachbox {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 500px;
    background: transparent;
    border-radius: 10px;
    border: 1px solid #B6B6B6;
}

.dashboardheader .serachbox input {
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.dashboardheader .serachbox input::placeholder {
    color: #777E90;
}

.dashboardheader .serachbox input:focus {
    box-shadow: none;
    color: #777E90;
}

.dashboardheader svg.searchicon {
    color: #000000;
    font-size: 30px;
}

.dashboardheader svg.baricon {
    font-size: 30px;
    color: #999;
    margin-right: 30px;
}

.headdropdoen .dropdown span img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 10px;
}


.avataricon {
    width: 35px;
    object-fit: contain;
}

.flagdrop.dropdown .dropdown-toggle::after {
    display: none;
}


button.notifibox.btn {
    position: relative;
}

span.badgeicon.badge {
    border-radius: 50%;
    font-size: 12px;
    background: linear-gradient(to right, #da8cff, #9a55ff) !important;
    position: absolute;
    top: 0px;
    right: -5px
}

svg.notificon {
    font-size: 22px;
    color: #fff;
}
/* NotificationDropdown.css */
.notification-toggle {
    position: relative;
    display: inline-block;
}

.notification-badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    font-size: 0.75rem;
}

.notification-dropdown-menu {
    right: 0; /* Ensure the dropdown aligns to the right */
    left: auto; /* Override default left alignment */
    width: 300px; /* Adjust width as needed */
    max-height: 400px; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
}

.notification-heading {
    font-weight: bold;
    text-align: center;
}

.notification-list {
    flex-grow: 1;
    overflow-y: auto;
}

.notification-footer {
    text-align: center;
    padding: 10px 0;
    background-color: white; /* Ensure the background color matches the menu */
    border-top: 1px solid #ddd; /* Add a top border for separation */
}

.see-all-link {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.notification-item {
    /* Add any additional styling for notification items here */
}

.notification-item.unread {
    background-color: #f8f9fa; /* Light gray background for unread notifications */
}

.notification-title {
    font-weight: bold;
}

.notification-description {
    color: gray;
}

.notification-time {
    font-size: 0.8em;
    color: lightgray;
}


.admindropdoen {
    margin-left: 10px;
}

.dashboardheader .dropdown-menu.show {
    min-width: 300px;
    /* left: 0; */
    position: absolute;
    top: 40px;
}


.dashboardheader .dropdown-toggle {
    white-space: nowrap;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 0px;
    border: none;
}

.dropdown-toggle::after {
    display: none !important;
   
}

svg.chevronicon {
    font-size: 22px;
}

.dashboardheader .dropdown-toggle:hover {
    color: #000;
}

.dropdown-toggle.show.btn {
    color: #fff;
    border: none;
}

.flagdrop.show.dropdown button {
    color: #000;
}




/*---------------------------------Dashboard  Header Exit --------------------------------- */