.wrapper {
    display: flex;
    align-items: stretch;
}


#content {
    /* padding: 20px; */
    min-height: 100vh;
    transition: all .8s ease;
    width: 100%;
    background: #FFF;
    /* height: 100vh; */
    overflow-y: scroll;
}

h5.headtitle {
    color: #14142C;
    font-size: 24px;
    font-weight: 500;
}

button.btn-secondary-btn3 {
    border-radius: 5px;
    background: aquamarine;
    color: #5878B9;
    font-size: 16px;
    font-weight: 500;
    font-family: 'PlusJakartaSans';
}

button.btn-secondary-btn3 {
    font-family: 'PlusJakartaSans';
    border-radius: 7px;
    color: #FFF;
    text-align: center;
    text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    background: linear-gradient(268deg, #4B91CE 0%, #5A73B5 100%);
}

button.btn-secondary-btn3:hover {
    color: #fff;
    background: linear-gradient(50deg, #4B91CE 0%, #5A73B5 100%);
}


.maintable {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.10);
    padding: 30px;
}

.maintable table tr td {
    background: transparent;
    color: #14142C;

}

.maintable table tr th {
    background: transparent;
    color: #fff;
}

.maintable table tr th {
    padding-bottom: 25px;
    color: #14142C;
    font-size: 15px;
    font-weight: 600;
}

.maintable table tr td {
    border: none;
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 16px;
    /* font-weight: 600; */
}

.formDiv label {
    color: #14142C;
    font-size: 16px;
    font-weight: 500;
}

.formDiv input {
    /* padding: 15px; */
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-weight: 400;
}

.formDiv input:focus {
    box-shadow: none;
    border-color: transparent;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}

.formDiv input::placeholder {
    font-size: 16px;
}

.printable11-content {
    display: none;
  }

  @media print {
    .printable11-content {
      display: block !important;
    }
  }
  
  @media print {
    .printableview-content {
      display: block !important;
    }
  }

  .printableview-content {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    display: none;
    margin: auto;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .headerview, .footerview {
    text-align: center;
    padding: 10px;
    background: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }
  
  .headerview h2 {
    margin: 0;
  }
  
  .patient-sectionview {
    padding: 15px;
    margin-top: 10px;
    background: #f9f9f9;
    border-radius: 5px;
  }
  
  .patient-sectionview h3 {
    color: #007bff;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  .fieldview {
    padding: 5px 0;
  }
  
  hr {
    border: 0;
    height: 1px;
    background: #ddd;
    margin: 10px 0;
  }
  
  .footerview {
    margin-top: 20px;
    font-size: 14px;
  }
  